import App from "@/App.vue";
import router from "@/router";
import store from "@/store/index";
import * as Sentry from "@sentry/vue";
import * as ActionCable from "actioncable";
import { createApp } from "vue";
import { GridApi } from "@ag-grid-community/core";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { registerSW } from 'virtual:pwa-register'
import UnmatchedDetailCellRenderer from "@/components/portfolio/unmatched/UnmatchedDetailCellRenderer.vue";
import PortfolioNoRowsOverlay from "@/components/portfolio/table/PortfolioNoRowsOverlay.vue";
import UnmatchedNoRowsOverlay from "@/components/portfolio/unmatched/UnmatchedNoRowsOverlay.vue";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061235}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Wondeur_AI}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Wondeur_AI_App}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Wondeur_AI_App}_need_to_be_licensed___{Wondeur_AI_App}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{15_December_2024}____[v3]_[0102]_MTczNDIyMDgwMDAwMA==3e1358c10cd1367f0f87f1b939a58302",
);

declare global {
  interface Window {
    gridApi?: GridApi;
  }
}

const app = createApp(App);

const getSentryEnvironment = (): string => {
  if (window.location.hostname === "app.wondeur.ai") {
    return "production";
  }
  if (window.location.hostname === "staging.app.wondeur.ai") {
    return "staging";
  }
  if (window.location.hostname === "localhost") {
    return "development";
  }
  return "default";
};

Sentry.init({
  Vue: app,
  dsn: "https://2ef991b3a9f34395a88508d5cb39f96f@sentry.io/1784090",
  environment: getSentryEnvironment(),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      showBranding: false,
      showName: false,
      showEmail: false,
      triggerLabel: "",
      triggerAriaLabel: "Send Feedback",
      formTitle: "Send Feedback",
      submitButtonLabel: "Send Message",
      messageLabel: "Message",
      messagePlaceholder: "Ask us a question, or send feedback.",
      successMessageText: "Thank you for your feedback.",
    }),
  ],
  tracesSampleRate: 0.25,
  replaysOnErrorSampleRate: 1,
  beforeSend(event, _) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      showSentryDialog(event);
    }
    return event;
  },
});

const showSentryDialog = (event: Sentry.ErrorEvent) => {
  Sentry.showReportDialog({
    eventId: event.event_id,
    user: {
      email: store.getters["user/user"]?.email,
      name: store.getters["user/name"],
    },
  });
};

const getWebsocketURL = (): string => {
  if (window.location.hostname === "app.wondeur.ai") {
    return "wss://api.wondeur.ai/cable";
  } else if (window.location.hostname === "staging.app.wondeur.ai") {
    return "wss://staging-api.wondeur.ai/cable";
  } else if (window.location.hostname === "localhost") {
    return "ws://localhost:8888/cable";
  } else if (
    window.location.hostname.includes("wondereur.com.beta.tailscale.net")
  ) {
    return `ws://${window.location.hostname.replace("app", "api")}:8888/cable`;
  } else if (window.location.hostname.includes("wondeur.ai")) {
    return "wss://staging-api.wondeur.ai/cable";
  } else {
    return `wss://${window.location.hostname.replace("app", "api")}/cable`;
  }
};

app.config.globalProperties.$cable =
  ActionCable.createConsumer(getWebsocketURL());

app.use(router);
app.use(store);
app.component('UnmatchedDetailCellRenderer', UnmatchedDetailCellRenderer);
app.component('PortfolioNoRowsOverlay', PortfolioNoRowsOverlay);
app.component('UnmatchedNoRowsOverlay', UnmatchedNoRowsOverlay);

app.mount("#app");

registerSW({
  onNeedRefresh() {
    store.commit("newContentAvailable", true);
  },
  onRegisteredSW(swUrl, r) {
    r && setInterval(async () => {
      if (!(!r.installing && navigator)) {
        return;
      }
  
      if (('connection' in navigator) && !navigator.onLine) {
        return;
      }
  
      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      }).catch(() => {
        return;
      });
  
      if (resp?.status === 200) {
        await r.update();
      }
    }, 60 * 60 * 1000);
  },
})
